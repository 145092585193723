@import "src/styles/variables.module";
@import "src/styles/mixins";

.splashDrawer {
  height: 100%;
  padding: 50px;
  background-image: url(../../../assets/backgrounds/simple-grayscale.png);
  background-size: cover;
  background-repeat: no-repeat;
  @include flex();

  .splashButtons {
    display: flex;

    button {
      background: transparent;
      border: 2px solid $primary;
      color: $primary;
      margin: 0 10px;

      &:hover {
        color: $secondary;
        border: 2px solid $secondary;
        @include box-shadow();
      }
    }

    @include media-query($antd-sm) {
      align-items: center;
      flex-direction: column;

      button {
        margin: 10px 0;
      }
    }
  }
}

.bottomRow {
  margin-top: 20px;
  @include flex(
    $justify-content: space-between,
    $align-items: center,
    $flex-direction: row
  );

  .copyright {
    font-size: 0.5rem;
    color: gray;
    @include flex(
      $flex-direction: row,
      $justify-content: center,
      $align-items: center
    );
    @include media-query($antd-sm) {
      margin-top: 10px;
      justify-content: center;
    }
  }

  .socialLinks {
    @include flex(
      $flex-direction: row,
      $justify-content: center,
      $align-items: center
    );
    @include media-query($antd-md) {
      margin-top: 10px;
      justify-content: center;
    }

    a {
      color: $dark;
      margin: 0 5px;

      svg {
        font-size: 1.2rem;
      }

      &:hover {
        color: $secondary;
      }
    }
  }
}
