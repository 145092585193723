@import "src/styles/variables.module";
@import "src/styles/mixins";

h2.hHeading {
  font-size: 2rem;
}
h3.hHeading {
  font-size: 2rem;
}
h4.hHeading {
  font-size: 1.5rem;
}
h5.hHeading {
  font-size: 1.25rem;
  font-weight: normal;
}
h6.hHeading {
  font-size: 1rem;
  font-weight: normal;
}

.sectionPadding {
  padding: 30px 50px;
  @include media-query($antd-md) {
    padding: 1rem;
  }
  &.noPaddingY {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
