@import "src/styles/variables.module";
@import "src/styles/mixins";

.labelSpacer {
  min-height: 31px;
  font-size: 1rem;
}

.centered {
  text-align: center;
}

.priceChoice {
  width: 100%;
  background: white;
  margin: 10px 0;
  font-size: 0.7rem;
  padding: 90px 20px;
  border: 3px solid $secondary;
  @include flex($flex-direction: row, $justify-content: space-between);

  .medName {
    color: $primary;
    font-weight: bold;
  }

  &.active {
    color: $white !important;
    background: $secondary;
    .dosage * {
      color: $white;
      text-align: center;
    }
    .medName {
      color: $white;
    }
    .individualPrice:hover {
      color: $white;
    }
  }

  &.isMostPopular {
    border: none;
    outline: 5px solid $warning;
    &.active {
      .dosage {
        color: black;
      }
    }
  }

  &.displayOnly {
    cursor: initial;
    &:hover {
      color: black;
    }

    .individualPrice:hover {
      color: black;
    }
  }

  > span {
    display: block;
    width: 100%;
  }

  .individualPrice {
    @include flex($flex-direction: row, $justify-content: space-between);
    &:hover {
      color: $primary;
    }
    .dosage {
      // color: $secondary;
      text-align: center;
    }

    .priceChoiceColumn {
      @include flex();
    }
  }
}
