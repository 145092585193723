@import "src/styles/variables.module";
@import "src/styles/mixins";

.mobileMenu {
  font-size: 1.2rem;
  line-height: initial;
  @include flex();

  .mobileMenuSectionContainer {
    margin-bottom: 30px;

    .mobileMenuSectionHeader {
      color: $secondary;
      font-weight: bold;
      font-size: 1.75rem;

      @include media-query($antd-sm) {
        font-size: 1.5rem;
      }
    }

    .mobileMenuList {
      font-size: 1.5rem;

      .mobileMenuListHeader {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 16px;
      }
      @include media-query($antd-sm) {
        font-size: 1.1rem;
      }

      :global(.ant-collapse-header) {
        font-size: 1.5rem;
        color: $secondary;
        padding: 0;
        @include media-query($antd-sm) {
          font-size: 1.1rem;
        }
      }
      :global(.ant-collapse-content) {
        ul > li > a {
          font-size: 1.5rem;
          @include media-query($antd-sm) {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .icon {
    & svg {
      font-size: 40px;
    }
  }
}
