@import "src/styles/variables.module";
@import "src/styles/mixins";

.authContainer {
  max-width: 600px;

  h1 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  :global(.ant-form-item-label) {
    font-weight: bold;
  }
}
