@import "src/styles/variables.module";
@import "src/styles/mixins";

.dashRow {
  max-width: 900px;
  margin: auto !important;

  .dashLink {
    margin: 0.5rem 0rem;
    color: $secondary-dark;
    cursor: pointer;
    background: $white;
    padding: 1rem;
    border-radius: 3px;
    @include flex();
    @include box-shadow();
    height: 90px;

    &:hover {
      @include box-shadow(true);
    }

    p {
      font-size: 1rem;
      text-transform: capitalize;
      margin: 0;
    }
    &.important {
      color: white;
      background-color: lighten($secondary-dark, 20);
    }
  }
}

.applicationContainer {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
}
