@import "src/styles/variables.module";

#dateRangeContainer {
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  h4 {
    margin-bottom: 0;
  }
  h5 {
    margin-bottom: 0;
  }
  @media (max-width: $antd-lg) {
    padding: 0rem 0.5rem;
  }
}

.sliderButtons {
  color: blue;
  cursor: pointer;
  text-align: right;
}

#rangePicker {
  margin: auto;
}

.timelapseSlider {
  :global(.ant-slider-mark span) {
    width: 100% !important;
  }
}

.googleMap {
  height: 55vh;
  width: 100%;
  border: 2px solid $dark;
  cursor: default !important;
}

.statisticsContainer {
  max-height: 55vh;
  overflow-y: scroll;
  .stateSelection {
    z-index: 9;
    position: sticky;
    top: 0;
    background: #fff;
    h4 {
      font-weight: bold;
    }
  }
  div h4 {
    font-weight: bold;
  }
}

#heatMapRow {
  .statistics {
    background: #fff;
    border-radius: 3px;
    @media (max-width: $antd-lg) {
      padding: 1rem;
    }
  }
}

@media (max-width: $antd-lg) {
  #heatMapRow {
    display: flex;
    flex-direction: column-reverse;
  }
  .reloadBtn {
    width: 100%;
  }
}
