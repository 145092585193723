.pageHeader {
  position: relative;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 2px solid #cbd2ea;
  margin-top: 1.4rem;
  margin-bottom: 20px;
  text-transform: capitalize;
  &:before {
    content: "";
    height: 10px;
    bottom: -5px;
    width: 45px;
    background: #0c89f9;
    position: absolute;
    left: 0;
  }
}
