@import "src/styles/variables.module";

.mainPanel {
  padding: 32px;
  padding-left: 226px;
  background-color: #f0f1f4;
  min-height: 100vh;
  @media (max-width: $antd-lg) {
    padding: 20px;
  }
}

:global(.ant-layout-sider-children svg) {
  width: 32px;
  height: 32px;
}

:global(.ant-menu-inline-collapsed) {
  width: 200px !important;
}

:global(.anticon + .ant-badge-count) {
  margin-right: 10px; // otherwise badge is too far to the right of icon
}
:global(.ant-badge-count) {
  z-index: 10;
}

:global(.ant-layout-sider-children) {
  :global(.ant-menu-item) {
    height: 62px;
    padding-top: 16px;
  }
}

// AntD Select Dropdown styling.
:global(.ant-select) {
  :global(.ant-select-selection-item) {
    margin: auto;
    line-height: 48px;
  }
  :global(.ant-select-multiple) {
    line-height: unset !important;
  }
}

:global(.ant-select-multiple) {
  :global(.ant-select-selection-item) {
    margin: 2px 4px 2px 0px;
    line-height: unset;
  }
}

:global(.ant-input) {
  border-radius: 3px;
}

:global(.ant-popover) {
  min-width: 300px;
  width: 100%;
  max-width: 450px;
  z-index: 1010;
  :global(.ant-popover-inner-content) {
    min-width: 300px;
    width: 100%;
    max-width: unset;
  }
}

@media (min-height: 568px) {
  :global(.ant-layout-sider-collapsed) .bottomItem {
    visibility: hidden;
  }
}
