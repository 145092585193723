@import "src/styles/variables.module";

.filesDropzone {
  & > span {
    display: flex;
  }

  .uploadButton {
    color: $dark;
    margin-top: 24px;
    background: $primary;
    font-weight: bold;

    &:hover {
      color: $primary;
      border: 2px solid $primary;
      background: transparent;
    }
  }
}

.filesDropzoneDragContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p {
    margin-bottom: 0;
  }

  & > img {
    margin-right: 16px;
  }

  & > div {
    text-align: left;
    color: $secondary;

    p:first-child {
      font-size: 16px;
    }

    p:last-child {
      font-size: 13px;
    }
  }
}

.fileList {
  margin-top: 25px;

  li {
    line-height: 28px;
    margin-top: 1em;
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
    border-bottom: 0.5px solid $medium;
  }

  .fileActionIcon {
    float: right;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }
  }
}

.note {
  color: $danger;
  margin-top: 24px;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 1.2em;
}
