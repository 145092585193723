@import "src/styles/variables.module";

.activePlansContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 1rem;
}

.subscriptionBtn {
  height: unset;
  padding: 1rem 2rem;
}

.paymentInfo {
  color: darken($light, 20%);
  font-size: 0.8rem;
}
