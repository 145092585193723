@import "src/styles/variables.module";

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $white;
  border: 1px solid $light;
  border-radius: 2px;

  & > img {
    display: none;
    width: 12px;
    height: 12px;
    margin: 2px;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  min-height: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & input:checked ~ .checkmark {
    border: 1px solid $secondary;
  }

  & input:checked ~ .checkmark > img {
    display: block;
  }

  & input:disabled ~ .checkmark {
    opacity: 0.5;
  }

  &:hover input ~ .checkmark {
    background-color: $light;
  }

  &:hover input:disabled ~ .checkmark {
    background-color: $white;
    cursor: not-allowed;
  }
}

.caption {
  line-height: 40px;

  &.disabledCaption {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
