@import "src/styles/variables.module";

#siderNav {
  background-color: #f7f7f7;
  :global(.ant-layout-sider-children) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  :global(.ant-menu-item) {
    background-color: transparent;
    &::after {
      opacity: 0;
    }
    a {
      span:last-child {
        margin-left: 10px;
      }
    }
    &:global(.ant-menu-item-selected) {
      border-radius: 0 50px 50px 0;
      background-color: #cfd4e1 !important;
      a {
        color: white;
        i span {
          color: white;
        }
      }
    }
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: 0px;
    background: transparent;
    overflow: visible;
    span {
      svg {
        margin-left: 0.5rem;
        width: 48px;
        height: 48px;
      }
      svg path {
        d: path(
          "M99.8 252.8L923.8 252.8C979.1 252.8 1023.8 297.5 1023.8 352.8L1023.8 352.8C1023.8 408.1 979.1 452.8 923.8 452.8L99.8 452.8C44.5 452.8 -0.2 408.1 -0.2 352.8L-0.2 352.8C-0.2 297.5 44.5 252.8 99.8 252.8Z M99.8 548.9L647 548.9C702.3 548.9 747 593.6 747 648.9L747 653.3C747 708.6 702.3 753.3 647 753.3L99.8 753.3C44.5 753.3 -0.2 708.6 -0.2 653.3L-0.2 648.9C-0.2 593.6 44.5 548.9 99.8 548.9Z"
        );
        fill: #3fd9b8;
      }
    }
  }
  .navMenu {
    background-color: transparent;
    padding-right: 1rem;
  }
}

#navLogout:hover {
  span {
    color: $secondary;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: $white;
  opacity: 0.4;
  z-index: 19;
}

.sideDrawer {
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 20;
  box-shadow: 2px 2px 15px 3px rgba(0, 0, 0, 0.06);
}

.navLogo {
  background: transparent;
  justify-content: center;
  display: flex;
  align-items: center;
  img {
    width: 123px;
  }
}

#navLogout {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  width: calc(100% + 1px);
  height: 62px;
  padding: 16px 0px 16px 24px;
  border-top: 1px solid #e3e6ed;
  font-weight: 600;
  cursor: pointer;
  svg {
    margin-right: 14px;
  }
}
