@import "src/styles/variables.module";
@import "src/styles/mixins";

.row {
  max-width: 1000px;
}

.col {
  padding: 1rem;
  border: 2px solid $light;
  border-radius: 4px;
  background-color: white;
  @include box-shadow();
}
