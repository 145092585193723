@import "src/styles/variables.module";

.actionContainer {
  display: flex;
  justify-content: space-evenly;
  svg {
    height: 24px;
    width: 24px;
    cursor: pointer;
    color: $secondary;
  }
}
