@import "src/styles/variables.module";
@import "src/styles/mixins";

.contactSection {
  background: $tertiary;
  color: white;

  .contactSectionContent {
    padding: 30px;

    svg {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }
}
