@import "src/styles/variables.module";

.evenRow {
  cursor: pointer;
  background-color: lighten($light, 13%);
}
.oddRow {
  cursor: pointer;
  background-color: lighten($light, 8%);
}

.listBorderBottom {
  :global(.ant-list-item) {
    border-bottom: 1px solid $light;
  }
}
