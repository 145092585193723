.uploadContainer {
  text-align: center;
  div {
    margin: auto;
  }
  :global(.ant-upload) {
    width: 132px;
    height: 132px;
  }
}

.avatarContainer {
  display: flex;
  flex-wrap: wrap;
  div {
    margin: 0.5rem auto;
    transition: all 0.2s ease-out;
  }
}

.uploadPic {
  width: 132px;
  border-radius: 50%;
}

.initials[data-letters]:before {
  cursor: pointer;
  content: attr(data-letters);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  color: white;
  transition: all 0.2s ease-out;
  width: 132px;
  height: 132px;
  line-height: 132px;
  font-size: 2rem;
  margin: auto;
  &:hover {
    opacity: 0.5;
    filter: grayscale(100%);
  }
}

.avatar {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
    filter: grayscale(100%);
  }
}
