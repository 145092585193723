@import "src/styles/variables.module";

.cardApplicationTag {
  cursor: pointer;
  &:hover {
    color: $primary;
    transform: scale(1.1);
  }
}

.cardPicture {
  height: 200px;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  @media (max-width: $antd-lg) {
    height: 150px;
  }
}

.noPicture {
  width: 100%;
  height: 200px;
  line-height: 200px;
  background-color: $light;
  text-align: center;
  font-weight: 700;
  overflow: hidden;

  @media (max-width: $antd-lg) {
    height: 120px;
    line-height: 120px;
  }
}

.floatTag {
  position: absolute;
  top: 0px;
  right: 8px;
  z-index: 3;

  &:global(.ant-tag) {
    // new Tag
    margin: 7px;
  }
}

.feedbackIconTag {
  color: $primary-dark;
  border: 1px solid $primary-dark;
  border-radius: 50%;
  background-color: $white;
  padding: 7px;

  &.like {
    color: $warning-dark;
    border: 1px solid $warning-dark;
  }
}

.propertyCardBody {
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  div p {
    margin-bottom: 0.8em;
    margin-top: 0.8em;
  }
}

.propertyCard {
  padding: 0.6rem;
  background: #fff;
  border-radius: 3px;
  transition: all 200ms ease-in-out;
  box-shadow: 0 6px 3px -3px rgba($dark, 0.6);
  &:hover {
    box-shadow: 6px 12px 6px -3px rgba($dark, 0.6);
  }
  @media (max-width: $antd-lg) {
    box-shadow: 0 6px 3px -3px rgba($dark, 0.6);
  }
  .imageContainer {
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    @media (max-width: $antd-lg) {
      flex-direction: column;
    }
  }
}

.paymentConfirmation {
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  margin: 0;
  border: 1px solid $light;
  margin-top: 0;
  margin-bottom: 0.5rem;
  &.paid {
    color: $primary-dark;
    background: lighten($primary-light, 20%);
  }
  &.unpaid {
    color: $danger;
    background: lighten($danger, 20%);
    cursor: pointer;
  }
  &.draft {
    color: $light;
    background: darken($light, 20%);
  }

  @media (max-width: $antd-lg) {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.propertyIdTag {
  background: $white;
  border: 1px solid $off-white;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  font-size: 13px;
  color: $medium;
  margin-left: 4px;
  margin-right: 4px;
}

.propertyInfo {
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  .propertyTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    color: black;
    opacity: 1;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.296296px;
    text-transform: capitalize;
    margin: 0;
    &.large {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  }
  .propertyTag {
    background: #ffffff;
    border: 1px solid #f0f1f4;
    border-radius: 3px;
    padding: 0.2rem 0.5rem;
    font-weight: 600;
    font-size: 13px;
    color: #cbd2e7;
    margin-left: 4px;
    margin-right: 4px;
  }
  .addressSnippet {
    margin: 0;
    text-transform: capitalize;
  }
  .roomTypes {
    margin: 0.5rem 0rem;
  }
  .rateInfo {
    text-align: right;
    font-weight: bold;
    margin: 0;
    margin-top: auto;
  }
}
