.medicationChoices {
  text-align: center;
  text-align: -webkit-center;
  a {
    width: 100%;
  }
}

.subscriptionRow {
  padding: 1rem 0rem;
  border-bottom: 1px solid gray;
}
