@import "src/styles/variables.module";

.applicationCard {
  margin: 1rem;
}

.coverPhoto {
  width: 240px;
  position: relative;
  margin: auto;
  img {
    height: 150px;
    width: 100%;
  }
  .tag {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}

.propertyInfo {
  p {
    margin: 0;
  }
  .title {
    font-weight: bold;
  }
}

.applicationMeta {
  p {
    margin: 0;
  }
  .contactInfo {
    border-bottom: 1px solid $light;
  }
}

.responseBtn {
  display: flex;
  button {
    margin: 0.5rem auto 0rem auto;
  }
  .reject {
    background-color: $danger;
    border-color: $danger;
    &:hover {
      background-color: lighten($danger, 10);
      border-color: lighten($danger, 10);
    }
  }

  .approve {
    background-color: $primary;
    border-color: $primary;
    &:hover {
      background-color: lighten($primary, 10);
      border-color: lighten($primary, 10);
    }
  }
}
