@import "src/styles/variables.module";

%presenceIndicator {
  position: absolute;
  font-size: 9px;
  left: 2px;
  top: 45%;
}

.link {
  color: $secondary;
  cursor: pointer;
}

.onlineIndicator {
  @extend %presenceIndicator;
  color: $warning-light;
}

.inlineOnlineIndicator {
  color: $warning-light;
  vertical-align: middle;
}

.offlineRecentIndicator {
  @extend %presenceIndicator;
  color: $warning;
}

.offlineIndicator {
  @extend %presenceIndicator;
  color: $dark;
}

.neverIndicator {
  @extend %presenceIndicator;
  color: $light;
}
