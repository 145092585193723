@import "src/styles/variables.module";
@import "src/styles/mixins";

.loadingPage {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  @include flex();
  overflow: hidden;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: $primary;

  .attribution {
    font-size: 0.25rem;
  }
}
