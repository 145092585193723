@import "src/styles/variables.module";
@import "src/styles/mixins";

%whiteText {
  color: $white;
}

.hSpace {
  font-size: 1.25rem;
  padding: 0;
  margin: 1rem;
  max-width: $max-textBlock-width;
  @include media-query($antd-md) {
    padding: 1rem;
  }
  & strong,
  & i,
  & u {
    font-size: 1.25rem;
    font-weight: 800;
  }
}

.whitePrimary {
  @extend %whiteText;
  background-color: $primary;

  & a {
    color: $secondary;
  }
}

.whiteSecondary {
  @extend %whiteText;
  background-color: $secondary;

  & a {
    color: $primary;
  }
}

.whiteTertiary {
  @extend %whiteText;
  background-color: $tertiary;

  & a {
    color: $secondary;
  }
}

.addPadding {
  padding: 4rem 3rem;
}
