.houseSearch {
  flex-direction: column;
  display: flex;
  align-items: center;
  max-width: 400px;

  p {
    text-align: center;
    margin: auto;
    padding-top: 0.5rem;
  }
}
