@import "src/styles/variables.module";
@import "src/styles/mixins";

.sectionContent {
  width: 100%;
  max-width: $max-content-width;
  margin: 0 auto;
  position: relative;

  &.sectionPadding {
    padding: 30px 50px;
  }
}
