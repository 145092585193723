@import "src/styles/variables.module";

.actionBtns {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  z-index: 99;

  button {
    margin: 0 !important;

    &:first-child {
      color: white;
      background-color: $medium;
      border-color: $medium;
      border-radius: 40px 0px 0px 40px;
      &:hover {
        background-color: lighten($medium, 15);
        border-color: lighten($medium, 15);
      }
    }

    &:last-child {
      border-radius: 0px 40px 40px 0px;
    }
  }
}
