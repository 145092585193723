@import "src/styles/variables.module";

.verifyLabel {
  :global(.ant-form-item-label) {
    text-align: center;
  }
}

.verifyContainer {
  display: flex;
  max-width: 400px;
  margin: auto;
  .verifyColumn {
    margin: auto;
  }
}

.resend {
  color: $danger;
  cursor: pointer;
}

.loginContainer {
  padding: 1rem;
  min-width: 280px;
}
