@import "src/styles/variables.module";
@import "src/styles/mixins";

.memberContainer {
  user-select: none;
  margin-bottom: 2rem;

  .status {
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 10px;
  }

  .membershipCard {
    height: 100%;
    margin: auto;
    padding: 1rem 2rem;
    background-color: white;
    border: 2px solid $light;
    transition: all 200ms ease-in-out;
    text-align: center;
    border-radius: 3px;
    // TODO1 Box shadow should be hover
    @include box-shadow();
    p {
      margin: 0;
    }

    .membershipIcon {
      width: 100%;
      max-width: 60px;
      margin: 1rem auto;
    }

    .per {
      color: lighten($medium, 15%);
    }

    .selectBtn {
      margin: 0.5rem auto;
      width: 100%;
      max-width: 180px;
    }

    ul {
      margin: 1rem auto;
      text-align: left;

      li {
        margin: 0.25rem 0;

        span {
          color: $secondary;
        }
      }
    }
  }
}
