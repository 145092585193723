@import "src/styles/variables.module";
@import "src/styles/mixins";

button.hButton {
  @include box-shadow($medium);
  @include flex();
  margin: auto;
  border: none;
  padding: 2rem 4rem;
  font-weight: 800;
  color: $white;
}

button.primary {
  background-color: $primary;
}

button.secondary {
  background-color: $secondary;
}

button.light {
  background-color: $off-white;
  & span {
    color: $text;
  }
}
