@import "src/styles/variables.module";

.healcoTabs {
  :global(.ant-tabs-nav-list) {
    width: 100%;
    // place-content: center;
    :global(.ant-tabs-tab) {
      max-width: 200px;
      width: 100%;
      text-align: center;
      white-space: normal;
      :global(.ant-tabs-tab-btn) {
        width: 100%;
      }
    }
    :global(.ant-tabs-tab-active) {
      background-color: $primary;
      :global(.ant-tabs-tab-btn) {
        color: #fff;
      }
    }
  }
}
