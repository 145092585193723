@import "src/styles/variables.module";
@import "src/styles/mixins";

.landingFooter {
  width: 100%;
  max-width: $max-content-width;
  @include center-margin();
  padding: 60px;
  background: $dark;
  text-align: center;
  @include media-query($antd-sm) {
    padding: 30px;
  }

  .topRow {
    .logoStarted {
      @include flex();

      .healcoLogo {
        width: 200px;
        @include media-query($antd-sm) {
          width: 100px;
          margin: auto;
        }
      }
    }

    .getStarted {
      margin-top: 20px;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      @include media-query($antd-sm) {
        font-size: 1.25rem;
      }
    }

    .landingFooterSitemapSection {
      font-size: 0.8rem;

      .landingFooterSitemapHeader {
        color: white;
        font-weight: bold;
        font-size: 1.1rem;
        @include media-query($antd-lg) {
          margin-top: 20px;
        }
      }

      .landingFooterSitemapSubHeader {
        color: white;
        margin-top: 10px;
      }

      // .landingFooterSitemapLink {
      //   // font-weight: lighter;
      // }
    }
  }

  .middleRow {
    margin-top: 30px;
    font-size: 0.8rem;
    @include media-query($antd-sm) {
      margin-top: 10px;
      @include flex(
        $flex-direction: column,
        $justify-content: center,
        $align-items: "center"
      );
    }
  }

  .bottomRow {
    margin-top: 30px;
    @include flex(
      $justify-content: space-between,
      $align-items: "center",
      $flex-direction: row
    );
    @include media-query($antd-sm) {
      margin-top: 0;
    }

    .copyright {
      font-size: 0.75rem;
      color: white;
      @include flex(
        $flex-direction: row,
        $justify-content: flex-start,
        $align-items: "flex-start"
      );
      @include media-query($antd-sm) {
        margin-top: 10px;
        justify-content: center;
      }
    }

    .socialLinks {
      @include flex(
        $flex-direction: row,
        $justify-content: flex-end,
        $align-items: "flex-start"
      );
      @include media-query($antd-sm) {
        margin-top: 10px;
        justify-content: center;
      }

      a {
        color: white;
        margin: 0 5px;

        svg {
          font-size: 1.2rem;
        }

        &:hover {
          color: $secondary;
        }
      }
    }
  }
}
