@import "src/styles/variables.module";
@import "src/styles/mixins";

.pillarHeading {
  height: 500px;
  position: relative;
  @include flex();

  &.backgroundImage {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &.overlay {
    @include transparent-overlay($color: $tertiary, $opacity: 0.8);
  }

  h2 {
    font-size: 5rem;
    margin: 0;
    z-index: $above;
  }

  @include media-query($antd-md) {
    height: 300px;

    h2 {
      font-size: 2.5rem;
    }
  }

  .pillarLink {
    padding: 40px 80px;
    position: absolute;
    bottom: -20px;
    z-index: $above;
    @include abs-horizontal-center();
    @include flex();
    @include media-query($antd-md) {
      padding: 30px 40px;
    }

    &:hover {
      color: white;
      background: $secondary;
    }

    a {
      font-weight: bold;
      font-size: 1.5rem;
      @include media-query($antd-md) {
        font-size: 1.25rem;
      }
    }
  }
}
