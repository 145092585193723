@import "src/styles/variables.module";
@import "src/styles/mixins";

.headerLinkSection {
  margin: 0 0 0 auto;
  width: 55%;
  @include flex($justify-content: space-between, $flex-direction: row);

  &.isScrolled {
    .headerLinksDropdown {
      .headerLinksDropdownHeader {
        color: $text;
      }
    }
  }

  .headerLinksDropdown {
    position: relative;
    flex: 1;
    @include flex($justify-content: stretch, $flex-direction: row);
    @include fade-child-in-on-hover(".headerLinksDropdownContainer");

    .headerLinksDropdownHeader {
      font-weight: bold;
      font-size: 1rem;
      color: white;
      @include center-margin;

      &.doNotStick {
        color: $text;
      }
    }

    .headerLinksDropdownContainer {
      position: absolute;
      background: white;
      padding: 20px;
      line-height: 25px;
      font-size: 1.0rem;
      width: $header-dropDown-width;
      top: 100%;
      @include box-shadow(true);
      @include add-arrow(up, white);

      .headerLinksDropdownSubHeader {
        font-weight: bold;
        margin-bottom: 7px;
      }
      .headerLinksDropdownLink {
        margin-bottom: 7px;
        margin-left: 5px;

        :global(.ant-collapse-header) {
          font-size: 1.0rem;
          padding: 0;
          color: $secondary;
        }

        :global(.ant-collapse-content) {
          ul > li {
            margin-bottom: 9px;
            font-size: 1.0rem;
          }
        }
        :global(.ant-collapse-content) {
          ul > li:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
