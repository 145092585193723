@import "src/styles/variables.module";

.category {
  font-size: 1.1rem;
  font-weight: bold;
  border-bottom: 1px solid $light;
  margin: 0.8rem 0;
}

.verify {
  color: $danger;
  cursor: pointer;
  margin-bottom: 24px;
}

.formContainer {
  margin-bottom: 0;
}
