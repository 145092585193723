@import "src/styles/variables.module";

.organizeHeader {
  h2 {
    border-bottom: 2px solid $secondary;
    display: inline-block;
  }

  p {
    font-weight: bold;
  }
}

.confirmContainer {
  position: fixed;
  bottom: 1rem;
  left: 50%;
}
