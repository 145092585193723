@import "src/styles/variables.module";

.uploadContainer {
  text-align: center;
  div {
    margin: auto;
  }
  :global(.ant-upload) {
    width: 132px;
    height: 132px;
  }
}

.fileContainer {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
}

.image {
  border: 1px solid black;
}

.delete {
  color: $danger;
}
