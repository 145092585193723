@import "src/styles/variables.module";

/*
 * Mixins
 - - - - -
 #Table of Contents:
 Absolute Horizontal Center
 Add Arrow
 Border Radius
 Box Shadow
 Fade In On Hover
 Flex Center
 Hide on Breakpoint
 Media Query
 Center Margin
 Transparent Overlay
*/

/*
 * Absolute Horizontal Center
 - - - - - - - - - - -
 Sets the width and centers an absolute-positioned element
 @param width: width of the element
 */
@mixin abs-horizontal-center() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/*
 * Add Arrow
 - - - -
 Adds a small arrow as an after psuedo element.
 It's up to the parent component to set the arrow's positioning.
 @param direction: up, down, right, left
 @param size: size
 @param color: color
 */
@mixin add-arrow($direction, $color, $size: $dropdown-arrow-size) {
  &:after {
    content: "";
    height: 0;
    width: 0;

    position: absolute;

    border-color: transparent;
    border-style: solid;
    border-width: $size / 2;

    @if $direction == up {
      border-bottom-color: $color;
      bottom: 100%;
      left: 45%;
      transform: translateX(-50%);
    } @else if $direction == right {
      border-left-color: $color;
      left: 100%;
      top: calc(50%);
      transform: translateY(-50%);
    } @else if $direction == down {
      border-top-color: $color;
      top: 100%;
      left: calc(50%);
      transform: translateX(-50%);
    } @else if $direction == left {
      border-right-color: $color;
      right: 100%;
      top: calc(50%);
      transform: translateY(-50%);
    } @else {
      @error "Unknown direction #{$direction}.";
    }
  }
}

/*
 * Border Radius
 - - - - - - - - - - -
 Fixed border radius
 @param radius: the border radius
 */
@mixin border-radius($radius: 10px) {
  border-radius: $radius;
}

/*
 * Box Shadow
 - - - - - - - - - - -
 Fixed box shadow
 @param active: if true, is a larger shadow (usually used with :hover)
 @param color: color of the shadow
 */
@mixin box-shadow($active: false, $color: $box-shadow) {
  box-shadow: 0 0 30px 15px $color;

  @if $active {
    box-shadow: 0 0 30px 15px $color;
  }
}

/*
 * Flex
 - - - - - - - - - - -
 Adds flex property to an element.
 By default, centers the children.
 Defaults to flex-direction column
 @param justify-content: primary axis
 @param align-itmems: secondary axis
 @param flex-direction: column / row
 */
@mixin flex(
  $justify-content: center,
  $align-items: center,
  $flex-direction: column
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
}

/*
 * Fade In On Hover
 - - - - - - - - - - -
 Fades in a child class on hover
 Add this to the parent
 @param breakpoint: the breakpoint to hide an element
 */
@mixin fade-child-in-on-hover($child) {
  #{$child} {
    opacity: 0;
    pointer-events: none;
    transition: $slow;
  }

  &:hover {
    #{$child} {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

/*
 * Hide on Breakpoint
 - - - - - - - - - - -
 Hides an element after reaching a certain breakpoint
 @param breakpoint: the breakpoint to hide an element
 */
@mixin hide-on-breakpoint($breakpoint: $antd-md) {
  @include media-query($breakpoint) {
    display: none;
  }
}

/*
 * Media Query
 - - - - - - - - - - -
 Media Query
 @param width: the screen width (unless very specific content, use an antd breakpoint variable)
 @param @content: SCSS block
 */
@mixin media-query($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

/*
 * Center Margin
 - - - - - - - - - - -
 Centers an element with margins
 */
@mixin center-margin() {
  margin: 0 auto;
}

/*
 * Transparent Overlay
 - - - - - - - - - - -
 A transparent overlay that will cover an entire element.
 Make sure the element is position: relative
 @param color: color of the overlay. Default is dark gray.
 @param opacity: how transparent the overlay is (or not)
 */
@mixin transparent-overlay($color: $dark, $opacity: 0.5) {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $color;
    opacity: $opacity;
    pointer-events: none;
  }
}
