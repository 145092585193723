@import "src/styles/variables.module";
@import "src/styles/mixins";

/*
 # Global Styles
  - - - - - - -
  Global style overrides and generic classes throughout the app

  Ant Design Overrides
  Utility Classes
 */

* {
  box-sizing: border-box;
  font-family: "Now";
  // scrollbar-color: auto $primary !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Header
h1,
h2,
h3,
h4 {
  font-family: "Now", sans-serif;
  // font-family: "Gilroy", sans-serif;
}

h1 {
  font-weight: 800;
}

/*
 # Ant Design Overrides
 */
:global(.ant-btn) {
  color: white;
  background: $secondary;
  border: 1px solid $secondary;
  @include border-radius(5px);

  &:global(.ant-btn-dashed) {
    background: none;
    color: $secondary;
    border: 1px dashed $medium;

    &:hover {
      border-color: $secondary;
    }
  }

  &:global(.ant-btn-text) {
    background: none;
    color: $secondary;

    &:hover {
      color: white;
      background: $secondary;
    }
  }
}

:global(.ant-image) {
  @include flex();

  img {
    width: 100%;
  }
}

/*
 # Utility Classes
 */

/*
 * Hidden
  - - - - - - - - - -
  Hides an element
 */
.hidden {
  display: none;
}

/*
 * Absolute Z-Index
  - - - - - - - - - -
  Above all other components
 */
.absoluteZindex {
  z-index: $absolute;
}
