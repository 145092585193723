@import "src/styles/variables.module";
@import "src/styles/mixins";

.informationSection {
  margin: 25px 0;

  h2 {
    color: $secondary;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  &.isError {
    h2 {
      color: $danger;
    }
  }

  &.centered {
    text-align: center;
  }
}
