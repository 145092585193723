@import "src/styles/variables.module";
@import "src/styles/mixins";

// * Settings Index
.profileContainer {
  background: $white;
  padding-top: 32px;
  border-bottom: 4px solid $primary;
  border-radius: 3px;
  margin-bottom: 18px;
  @include box-shadow(true);

  .profilePic {
    display: flex;
    margin: auto;
    width: 132px;
    height: 132px;
    border-radius: 200px;
    margin-bottom: 16px;

    transition: all 0.2s ease-out;
    &:hover {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }
  p {
    text-align: center;
    &:last-child {
      margin-bottom: 20px;
    }
  }
  .profileName {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .accent {
    color: $medium;
  }
}

.picContainer {
  cursor: pointer;
}

.initials[data-letters]:hover::before {
  background: $light;
}

.initials[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  color: white;
  transition: all 0.2s ease-out;
  width: 100%;
}
.profile {
  margin: auto;
  width: 132px;
  height: 132px;
  line-height: 132px;
  font-size: 2rem;
}
.organization {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 1rem;
}

.organizationContainer {
  background: $white;
  @include box-shadow(true);
  border-bottom: 4px solid $secondary;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  margin-bottom: 18px;
  p {
    font-weight: bold;
    font-size: 1.05rem;
    margin: 0;
  }
  .orgPic {
    width: 64px;
    height: 64px;
  }
  .orgInfo {
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .orgName {
      color: $secondary;
    }
  }
}

.notificationContainer {
  cursor: pointer;
  color: $secondary;
  text-align: center;
  background: $white;
  font-weight: bold;
  @include box-shadow(true);
  border-bottom: 4px solid $tertiary;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  margin-bottom: 18px;
  div {
    margin: auto;
  }
}

.tabContainer {
  padding: 24px;
  background: $white;
  @include box-shadow(true);
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    right: 0px;
    border-color: $primary;
    border-style: solid;
    border-width: 7px 7px 0 0;
    border-radius: 0px 0px 0px 100%;
  }

  h2 {
    font-weight: bold;
    text-transform: capitalize;
  }
}
