@import "src/styles/variables.module";
.tagGroupWrapper {
  display: flex;
  margin: 1rem 0rem;

  @media screen and (max-width: $antd-md) {
    display: block;
  }
  .tag {
    display: inline-block;
    padding: 4px 16px;
    background-color: #fff;
    border-radius: 15.5px;
    margin: auto;
    line-height: 21px;
    cursor: pointer;

    &.selectedTag {
      background-color: #fff;
      color: $secondary;
      border: 0.5px solid $secondary;
      cursor: default;
    }
    span {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
