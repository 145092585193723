@import "src/styles/variables.module";
@import "src/styles/mixins";

.authTemplate {
  background: url("../../../../assets/backgrounds/simple-grayscale.png")
    no-repeat;
  background-size: cover;
  background-position: bottom;

  :global(.ant-layout-header) {
    width: 100%;
    height: $header-height;
    background: none;
    padding: 0;
    position: relative;
    z-index: $top;
  }

  :global(.ant-layout-content) {
    width: 100vw;
    max-width: 100%;
    padding: 50px 0;
    @include flex();
    @include media-query($antd-sm) {
      justify-content: flex-start;
    }

    :global(.ant-image) {
      width: 200px;
    }
  }

  :global(.ant-layout-footer) {
    padding: 0;
    background: $dark;
  }
}
