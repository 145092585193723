@import "src/styles/variables.module";

.question {
  font-weight: bold;
}

.approve {
  background-color: $primary;
  border-color: $primary;
  &:hover {
    color: $white;
    background-color: lighten($primary, 10);
    border-color: lighten($primary, 10);
  }
}

.reject {
  background-color: $danger;
  border-color: $danger;
  &:hover {
    color: $white;
    background-color: lighten($danger, 10);
    border-color: lighten($danger, 10);
  }
}
.prescriptionSubmission {
  :global(.ant-form) {
    text-align: start;
  }
}
