@import "src/styles/variables.module";
@import "src/styles/mixins";

.hCard {
  border-radius: 20px;
  border: 1px solid $light;
  margin: 2rem;
  @include box-shadow($color: $light);
  position: relative;
  overflow: hidden;

  @include media-query($antd-md) {
    margin: 1rem;
    @include box-shadow(true);
  }

  &.noMargin {
    margin: 0;
  }

  .topRightCornerBean {
    height: 125px;
    position: absolute;
    top: -35px;
    right: -325px;
    transform: rotate(-185deg);
  }

  .bottomRightCornerBean {
    height: 125px;
    position: absolute;
    bottom: -25px;
    right: -325px;
    transform: rotate(250deg);
  }

  .bottomLeftCornerBean {
    height: 125px;
    position: absolute;
    bottom: -25px;
    left: -325px;
    transform: rotate(-250deg) scaleX(-1);
  }
  .topLeftCornerBean {
    height: 125px;
    position: absolute;
    top: -35px;
    left: -325px;
    transform: rotate(185deg) scaleX(-1);
    transition: all 0.3s ease-in-out 0s;
  }

  .hCardContent {
    position: relative;

    .hCardTitle {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 1rem;
      & h3 {
        color: $white;
        margin-bottom: 0;
      }
      &.primary {
        background-color: $primary;
      }
      &.secondary {
        background-color: $secondary;
      }
      &.tertiary {
        background-color: $tertiary;
      }
    }

    .hCardBody {
      padding: 2rem 3rem;
      border-radius: 20px;
      @include media-query($antd-md) {
        padding: 2rem 1rem;
      }
    }
  }
}
