@import "src/styles/variables.module";
@import "src/styles/mixins";

.hList {
  @include flex();
  margin-bottom: 20px;

  ul {
    padding-left: 2rem;
    li {
      list-style-type: disc;
      padding-bottom: 1rem;
    }
  }
}

.pointer {
  cursor: pointer;
}

.left {
  padding: 0;
  align-items: flex-start;
}

.numbered {
  padding-left: 2rem;

  & > li {
    list-style: decimal;
    margin-bottom: 1rem;
  }
}

.biggerText {
  font-size: 1.25rem;

  & strong,
  & u,
  & i {
    font-size: 1.25rem;
  }
}

.noMarker {
  ul {
    & > li {
      list-style-type: none;
    }
  }
}

.noPadding {
  ul {
    padding: 0;
  }
}

.noMargin {
  margin: 0;
}
