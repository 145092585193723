/* Gagalin */
@font-face {
  font-family: "Gagalin";
  src: url("Gagalin-Regular.otf") format("opentype");
  /* font-weight: 100;
  font-style: ; */
}

/* Gilroy */
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Now */

@font-face {
  font-family: "Now";
  src: local("Sans-serif"), url("Now-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Now";
  src: local("Sans-serif"), url("Now-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Now";
  src: local("Sans-serif"), url("Now-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Now";
  src: url("Now-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Now";
  src: url("Now-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Now";
  src: local("Sans-serif"), url("Now-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NowAlt";
  src: url("NowAlt-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "NowAlt";
  src: url("NowAlt-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NowAlt";
  src: url("NowAlt-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NowAlt";
  src: url("NowAlt-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NowAlt";
  src: url("NowAlt-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "NowAlt";
  src: url("NowAlt-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
