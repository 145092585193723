@import "src/styles/variables.module";
@import "src/styles/mixins";

.hStrong {
  & strong,
  & u,
  & i {
    font-size: 1.25rem;
    font-weight: 800;
  }
}
