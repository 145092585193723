@import "src/styles/variables.module";

.hBanner {
  width: 100%;
  height: $banner-height;
  line-height: $banner-height;
  border: none;
  background: $primary;
  text-align: center;
  position: fixed;
  z-index: $absolute;
  font-size: 0.8rem;
  font-weight: bold;

  span {
    color: white;
  }

  b {
    font-size: 0.8rem;
    color: $secondary;
  }
  @media (max-width: $antd-sm) {
    font-size: 0.45rem;
  }
}
