@import "src/styles/variables.module";
@import "src/styles/mixins";

%whiteText {
  color: $white;
}

.hText {
  @include flex($align-items: flex-start);
  margin-bottom: 20px;
  & strong,
  & u,
  & i {
    font-weight: 800;
  }
  &.noMargin {
    margin-bottom: 0;
  }

  &.addMarginTop {
    margin-top: 30px;
  }

  &.centered {
    align-items: center;
  }

  &.whitePrimary {
    @extend %whiteText;

    & a {
      color: $secondary;
    }
  }

  &.whiteSecondary {
    @extend %whiteText;

    & a {
      color: $primary;
    }
  }
  &.sectionPadding {
    padding: 30px 50px;
    @include media-query($antd-md) {
      padding: 1rem;
    }
  }
  &.biggerText,
  & strong,
  & u,
  & i {
    font-size: 1.25rem;
  }
}
