@import "src/styles/variables.module";
@import "src/styles/mixins";

.sectionBackground {
  width: 100%;
  position: relative;
}
.backgroundImage {
  @include transparent-overlay($opacity: 0.1);
}
