@import "src/styles/variables.module";
@import "src/styles/mixins";

.hHeaderBackground {
  background: none;
  transition: $fast;

  &:not(.isBannerClosed) {
    margin: $banner-height auto 0 auto;
    &:not(.isUnsupportedBrowser) {
      margin: $banner-height + $banner-height auto 0 auto;
    }
  }

  &:not(.isUnsupportedBrowser) {
    margin: $banner-height auto 0 auto;
  }

  &.isScrolled {
    &:not(.doNotStick) {
      background: white;
    }
  }

  .hHeader {
    height: 70px;
    padding: 20px 50px;
    max-width: $max-content-width;
    z-index: $top2;
    position: relative;

    transition: $fast;
    @include flex($justify-content: space-between, $flex-direction: row);
    @include media-query($antd-sm) {
      padding: 0 20px;
    }

    .headerImage {
      width: 80px;
      height: 68px;
      padding: 5px;
    }
  }

  button {
    margin: 0;
  }
}
