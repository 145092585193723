@import "src/styles/variables.module";
// * Show Info
.accountContainer {
  .infoContainer {
    div p {
      width: 100%;
      white-space: normal;
    }

    div {
      margin-bottom: 0.8rem;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
      border-bottom: 1px solid $light;
    }
  }

  .editContainer:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 1px solid $light;
  }
}

.flexInfo {
  display: flex;
  justify-content: space-between;
  div p {
    text-transform: capitalize;
  }
}

.infoContainer {
  padding: 0rem 1rem;
  font-size: 1rem;
  div {
    text-align: center;
    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }
  .label {
    margin: 0;
    text-align: left;
    & + p {
      text-align: left;
    }
  }
}

.infoLabel {
  font-size: 1.1rem;
  font-weight: bold;
}

// * Edit Info
.editBtn {
  margin-bottom: 1rem;
  text-align: right;
  button {
    color: white;
    background-color: $primary;
    border-color: $primary;
    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}

.editContainer {
  padding: 0rem 1rem;
  font-size: 1rem;
}

.editLabel {
  div {
    font-size: 1.1rem;
    font-weight: bold;
    div > label {
      font-size: 0.9rem;
      font-weight: normal;
      text-align: left;
      margin-left: 8px;
    }
  }
}

.actionBtns {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  z-index: 99;
  button {
    margin: 0 !important;
    &:first-child {
      color: white;
      background-color: $medium;
      border-color: $medium;
      border-radius: 40px 0px 0px 40px;
      &:hover {
        background-color: lighten($medium, 15);
        border-color: lighten($medium, 15);
      }
    }
    &:last-child {
      border-radius: 0px 40px 40px 0px;
    }
  }
}
