@import "src/styles/variables.module";

.pageTitle {
  margin: 15px 0;
  max-width: 50%;
  @media screen and (max-width: $antd-md) {
    max-width: 100%;
  }

  h2 {
    color: $dark;
    font-weight: bold;
    font-size: 1.4rem;
    border-bottom: 2px solid $secondary;
    margin-bottom: 10px;
    padding-bottom: 10px;
    @media (max-width: $antd-lg) {
      width: 60%;
    }
  }

  p {
    margin: 10px 0;
    font-weight: bold;
  }

  .subtitle {
    color: $secondary;
  }
}
