@import "src/styles/variables.module";
@import "src/styles/mixins";

button.scrollUp {
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 35px;
  right: 35px;
  z-index: $top;

  & span {
    border-radius: 50%;
    & svg {
      color: $primary;
      font-size: 3rem;
      border-radius: 50%;
    }
  }

  :hover {
    @include box-shadow;
  }
}
