@import "src/styles/variables.module";
.column {
  display: flex;
  flex-direction: column;
  @media (max-width: $antd-lg) {
    border-bottom: 1px solid black;
  }
}

.hidden {
  display: none;
}

.photo {
  padding: 0.5rem;
  width: 100%;
  max-width: 250px;
}

.block {
  display: inline-block;
  cursor: pointer;
  width: 100px;
  height: 100%;
  background-color: black;
}
