@import "src/styles/variables.module";

.adminControls {
  position: relative;
  .finishedSelection {
    margin-left: auto;
  }
}

:global(.ant-popover-inner-content) {
  max-width: 275px;
}

.cardGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 360px);
  grid-gap: 30px;
  margin-top: 20px;
  border-top: 1px solid $light;
  padding: 20px;
  @media (max-width: $antd-sm) {
    grid-template-columns: repeat(auto-fit, 290px);
  }
}

.disabledCard {
  background-color: $light !important;
  img {
    filter: opacity(0.9);
  }
  video {
    filter: opacity(0.9);
  }
}

.propertyProgressContainer {
  display: flex;
  justify-content: space-between;
}

.propertyProgressBar {
  width: 100px;
  height: 6px;
  background-color: $light;
  border-radius: 5px;
  transition: 0.4s ease-in-out;
  &.active {
    background-color: $primary-light;
  }
  &.error {
    background-color: $danger;
  }
  @media (max-width: $antd-lg) {
    width: 80px;
  }
}

.propertyProgressText {
  color: $primary-dark;
  opacity: 0.4;
  font-weight: bold;
  transition: 0.4s ease-in-out;
  &.active {
    color: $primary;
  }
  &.error {
    color: $danger;
  }
  &.progressed {
    opacity: 1;
  }
}

:global(.ant-card-body) {
  padding: 0.1rem;
  padding-top: 0.5rem;
  div div {
    color: $dark;
  }
}

@keyframes pulse {
  0%,
  80%,
  90%,
  100% {
    color: $secondary;
  }
  85%,
  95% {
    color: $warning-dark;
  }
}
