@import "src/styles/variables.module";

/*
 * Landing Layout Template
 - - - - - - - - - - - - - -
 Antd Layout Overrides
 These are not in Global styles since we use different Layouts throughout the app
 */

.landingTemplate {
  &:global(.ant-layout) {
    width: 100vw;
    max-width: 100%;
    background: none;
  }

  :global(.ant-layout-header) {
    width: 100%;
    position: fixed;
    z-index: $top;
    background: none;
    padding: 0;
    margin: 0;
    &.doNotStick {
      position: initial;
    }
  }

  :global(.ant-layout-footer) {
    width: 100%;
    background: $dark;
    height: 600px;
    font-size: 1rem;
    padding: 0;
  }
}
