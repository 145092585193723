/*
 * Variables
*/

// Antd Breakpoints
$antd-xxl: 1600px;
$antd-xl: 1200px;
$antd-lg: 992px;
$antd-md: 768px;
$antd-sm: 576px;

// COLORS
$primary: #30d6b3;
$primary-light: #50eac9;
$primary-dark: #259c82;
$secondary: #2d8dfa;
$secondary-light: #5a8fcc;
$secondary-dark: #2574ce;
$secondary-transparent: rgba(45, 141, 250, 0.75);
$warning: #ffdc84;
$warning-dark: #ecce31;
$warning-light: #52c41a;
$danger: #ee4f4f;
$danger-dark: #b33b3b;
$light: #ced7df;
$medium: #777;
$dark: #323a43;
$dark-transparent: rgba(20, 23, 26, 0.75);
$white: #ffffff;
$off-white: #f0f0f0;
$black: #000;
$stripe-blue: #008cdd;
$tertiary: #4a4968;
$box-shadow: #acacac25;
$box-shadow-secondary: #00000004;
$text: #00000095;

// Z-INDEX
$absolute: 1000;
$top3: 300;
$top2: 200;
$top: 100;
$above: 10;
$below: -10;

// TRANSITION SPEEDS
$slow: 0.5s;
$fast: 0.25s;

// Landing
$banner-height: 35px;
$header-height: 100px;
$header-dropDown-width: 230px;
$dropdown-arrow-size: 30px;
$max-content-width: $antd-xl;
$max-textBlock-width: $antd-md;

:export {
  primary: $primary;
  primaryLight: $primary-light;
  primaryDark: $primary-dark;
  secondary: $secondary;
  secondaryLight: $secondary-light;
  secondaryDark: $secondary-dark;
  secondaryTransparent: $secondary-transparent;
  warning: $warning;
  warningDark: $warning-dark;
  warningLight: $warning-light;
  danger: $danger;
  dangerDark: $danger-dark;
  light: $light;
  medium: $medium;
  dark: $dark;
  darkTransparent: $dark-transparent;
  white: $white;
  offWhite: $off-white;
  black: $black;
  stripeBlue: $stripe-blue;
  tertiary: $tertiary;
  boxShadow: $box-shadow;
  boxShadowSecondary: $box-shadow-secondary;
  text: $text;

  antdXxl: $antd-xxl;
  antdXl: $antd-xl;
  antdLg: $antd-lg;
  antdMd: $antd-md;
  antdSm: $antd-sm;

  absolute: $absolute;
  top3: $top3;
  top2: $top2;
  top: $top;
  above: $above;
  below: $below;
}
