@import "src/styles/variables.module";
@import "src/styles/mixins";

.signUpChoice {
  height: 160px;
  width: 100%;
  max-width: 350px;
  padding: 20px;
  white-space: normal;
  background: white;
  color: $text;
  font-weight: bold;
  font-size: 1.1rem;
  border: none;
  @include border-radius();
  @include box-shadow();
  @include flex();
  @include media-query($antd-sm) {
    padding: 10px;
    font-size: 1rem;
  }

  svg {
    color: $secondary;
    font-size: 2rem;
  }

  span {
    // antd override
    margin: 0 !important;
  }

  .subText {
    color: $light;
    font-size: 0.8rem;
    margin-top: 10px;
  }

  &.active {
    svg {
      color: $white;
    }
  }
}
